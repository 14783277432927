.sliderWrapper {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100px;
  height: 100%;
  width: var(--sliderWidth);
}

.slider {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.showOverflow {
  overflow: visible;
}

.slide {
  overflow: visible;
  width: 100%;
  height: 100%;
  min-width: 100%;
  max-width: 100%;
}

.dots {
  height: 40px;
}
